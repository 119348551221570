/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap'); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

body {
  font-family: 'Noto Sans', serif;
  font-weight: 400;
  background: rgb(250, 250, 250);
}

a {
  color: #202124;
  text-decoration: none;
}

div {
  line-height: 1.4;
}

span {
  line-height: 1.4;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.4;
  font-family: 'Noto Sans', serif;
  color: #202124;
}

@keyframes addBottomPaddingAnimation {
  0% {
    padding-bottom: 0px;
  }

  100% {
    padding-bottom: 20px;
  }
}

.introLetter {
  vertical-align: middle;
}

.introHeadingBottomPadding {
  animation: addBottomPaddingAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes subheadingAnimation {
  0% {
    padding-bottom: 0px;
    opacity: 0;
  }

  100% {
    padding-bottom: 100px;
    opacity: 1.0;
  }
}

.introSubheading {
  padding-top: 50px;
  font-size: 18px;
  color: rgb(80, 80, 80);
  min-width: 290px;
  text-align: center;

  animation: subheadingAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.speedPresetButton {
  height: 50px;
  width: 105px;
  font-size: 16px;
  font-weight: 500;
  margin: 6px;
  box-shadow: 0 0px 6px rgba(152, 147, 147, 0.14);
  border-radius: 16px;
}

.clipCardBookmarkButton {
  vertical-align: middle;
  padding: 0px;
  margin-top: 6px;
}

.heading {
  font-size: 23px;
  color: #202124;
  font-weight: 700;
  line-height: 1.4;
}

.sectionAndTranscriptHeading {
  font-size: 20px;
  color: #202124;
  line-height: 1.4;
  font-weight: 600;
}

.appName {
  width: 152px;
}

.clipTitleForPreview {
  font-size: 18px;
  margin: 0px 0px;
  color: rgb(30, 30, 30);
  line-height: 1.4;
  font-weight: 500;
}

.clipTitle {
  font-size: 18px;
  margin: 0px 0px;
  color: rgb(30, 30, 30);
  line-height: 1.4;
  font-weight: 500;
}

.clipTitleLarge {
  font-size: 20px;
  margin: 0px 0px;
  color: rgb(30, 30, 30);
  line-height: 1.4;
  font-weight: 600;
}

.sourceName {
  font-size: 15px;
  color: #202124;
  font-weight: 500;
}

.sourceTitle {
  font-size: 14px;
  /* color: rgb(80, 80, 80); */
  font-weight: 400;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdl-shadow--2dp {
  box-shadow: none !important;
}

.trackInfoSourceTitle {
  font-size: 18px;
  color: rgb(30, 30, 30);
  font-weight: 600;
  line-height: 1.4;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackInfoSourceImage {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  vertical-align: middle;
}

.shuffleTopClips {
  margin: 30px 0px;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  background: rgb(30, 30, 30);
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.speedButton {
  padding: 8px 16px;
  color: #202124;
}

.loading {
  background: linear-gradient(-45deg, rgb(234, 234, 238), rgb(228, 228, 232));
}

.flipHoriz {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.homeHeaderButton {
  margin-left: 30px
}

.subheading {
  color: rgb(80, 80, 80);
  font-size: 16px;
  font-weight: 400;
}

.sectionsHeading {
  background: rgba(242, 242, 242, 0.4);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.transcriptSubheading {
  font-size: 20px;
  font-weight: 600;
  color: #202124;
}

.underline {
  text-decoration: underline;
  text-decoration-color: rgba(130, 130, 130, .5);
  color: rgb(130, 130, 130);
}

.profileAndSourceImageContainer {
  position: relative;
  text-align: center;
}

.profilePicWithSourceImage {

}

.sourceImageOverlay {
  position: absolute;
  bottom: 0px;
  margin-left: 25px;
  border-radius: 4px;
  /* right: 0px; */
  width: 35px;
  height: 35px; /* Adjust size as needed */
}

.clipLargePreviewTitle {
  font-size: 18px;
  font-weight: 600;
  color: rgb(30, 30, 30);
  text-align: left;
  margin: 12px 0px;
}

.clipLargePreviewSourceName {
  font-size: 14px;
  font-weight: 500;
  color: rgb(30, 30, 30);
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clipLargePreviewDetails {
  font-size: 14px;
  color: rgb(120, 120, 120);
  text-align: left;
}

.clipLargePreviewSpeakerName {
  font-size: 14px;
  font-weight: 500;
  color: rgb(30, 30, 30);
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clipLargePreviewSpeakerDescription {
  font-size: 14px;
  color: rgb(120, 120, 120);
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.clipSearchResultTitle {
  font-size: 16px;
  font-weight: 500;
  color: rgb(30, 30, 30);
  text-align: left;
}

.clipSummary {
  font-size: 14px;
  color: rgb(80, 80, 80);
}

.topicImage {
  /* border: 2px solid rgba(215, 227, 252, 0.9); */
  /* border: 2px solid rgb(178, 178, 254);
  padding: 2px; */
  object-fit: cover;
  border-radius: 18%;
}

.slowerFasterButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  vertical-align: middle;
  background: white;
}

.voteButton {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  text-align: center !important;
  border: 1px solid rgb(224, 224, 224);
  height: 44px;
  border-radius: 8px;
}

.picContainer {
  position: relative;
  top: 0;
  left: 0;
  text-align: left;
}

.profilePicLink {
  border: 2px solid;
  padding: 2px;
}

.container {
  max-width: 540px;
  min-height: 100vh;
  margin: 0px auto;
  overflow: auto;
  background: white;

  padding-bottom: env(safe-area-inset-bottom);
}

.homeContainer {
  max-width: 540px;
  max-height: 100vh;
  margin: 0px auto;
  overflow: hidden;
  border-right: 1px solid rgb(224, 224, 224);
  border-left: 1px solid rgb(224, 224, 224);
  padding-bottom: calc(68px + env(safe-area-inset-bottom));
}

@keyframes heartUpAnimation {
  0% {
    scale: 1
  }

  50% {
    scale: 1.20;
  }

  100% {
    scale: 1.0;
  }
}

.heartUp {
  animation: heartUpAnimation ease 0.5s;
  /* animation-iteration-count: 1; */
  animation-fill-mode: forwards;
}

.tipContainer {
  font-size: 16px;
  padding: 60px 20px;
  text-align: center;
}

.circleNumber {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  background: rgb(248, 248, 248);
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.deviceButton {
  padding: 6px 30px;
  border-radius: 20px;
  margin: 0px 10px;
}

/* .spin {
  -webkit-animation:spin 1s linear infinite;
  -moz-animation:spin 1s linear infinite;
  animation:spin 1s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } } */

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#transcript {
  overflow-y: auto;
}

.selectedTopic {
  font-size: 16px;
  padding: 8px 14px;
  margin: 0px 8px 0px 0px;
  border-radius: 8px;
  display: inline-block;
  height: 40px;
  font-weight: 700;

  background: rgb(242, 242, 242);
  color: rgb(30, 30, 30);
}

.unselectedTopic {
  font-size: 16px;
  padding: 8px 14px;
  margin: 0px 8px 0px 0px;
  border-radius: 8px;
  display: inline-block;
  height: 40px;
  font-weight: 400;

  background: rgb(242, 242, 242);
  color: rgb(124, 124, 124);
}

.topicName {
  font-size: 22px;
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.searchSuggestion {
  padding: 10px 12px;
  border-radius: 8px;
  display: block;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.selectedTopicDisplay {
  font-size: 16px;
  padding: 8px 0px;
  margin: 0px 20px 0px 0px;
  border-radius: 8px;
  display: inline-table;
}

.forMeButton {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid rgb(224, 224, 224);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 14px;
  border-radius: 8px;
}

.highlight {
  background: rgb(255, 255, 225);
}

.startsSpeaking {
  font-size: 14px;
  color: #6748FF;
}

.desktopPrevNextButton {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid rgb(224, 224, 224);
  background: white;
}

.fixedSafeBottom {
  position: fixed;
  bottom: calc(0px + env(safe-area-inset-bottom));
  width: 100%;
}

.homePreview {
  font-size: 25px;
  text-align: center;
}

.homePreviewSubtitle {
  font-size: 15px;
  font-weight: 380;
  color: rgb(80, 80, 80);
}

[data-stickto] {
  position: fixed;
}

[data-stickto="bottom"] {
  bottom: 0;
}

.exampleTopicEmoji {
  width: 100%;
  padding: 2px;
  font-size: 37px;
}

.homeButton {
  width: 271px;
  background: black;
  border-radius: 20px;
  padding: 16px 20px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin: 0px auto;
}

.homeButtonLight {
  width: 271px;
  background: white;
  border-radius: 20px;
  padding: 16px 20px;
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin: 0px auto;
  border: 0.5px solid rgb(80, 80, 80);
}

.exampleSpeakerImage {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  padding: 1px;
  border: 0.5px solid rgb(224, 224, 224);
}

.introStartButton {
  /* box-shadow: 0 0px 6px rgba(0, 0, 0, 0.14); */
  padding: 12px 26px;
  border-radius: 16px;
  /* background: linear-gradient(224deg, rgb(215, 227, 252, 0.35) 0%, rgb(186, 223, 201, 0.35) 100%); */
  /* background: linear-gradient(224deg, #dec8e550 0%, #d7e3fc50 100%); */
  background: rgb(242, 242, 242);
  vertical-align: middle;

  font-size: 15px;
  font-weight: 500;
}

.clipStartButton {
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.14);
  min-width: 280px;
  max-width: 400px;
  padding: 30px 20px;

  font-size: 18px;
  border-radius: 20px;
  /* background: linear-gradient(224deg, rgb(215, 227, 252, 0.35) 0%, rgb(186, 223, 201, 0.35) 100%); */
  /* background: linear-gradient(224deg, #dec8e550 0%, #d7e3fc50 100%); */
  background: rgb(30, 30, 30);
  color: rgb(251, 250, 252);
  vertical-align: middle;

  animation: pulseIntroButtonAnimation ease-in-out 3.6s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.sharedClipStartButton {
  min-width: 320px;
  padding: 20px 20px;

  font-size: 18px;
  border-radius: 20px;
  /* background: linear-gradient(224deg, rgb(215, 227, 252, 0.35) 0%, rgb(186, 223, 201, 0.35) 100%); */
  /* background: linear-gradient(224deg, #dec8e550 0%, #d7e3fc50 100%); */
  background: white;
  color: rgb(30, 30, 30);
  border: 1px solid rgb(224, 224, 224);
  vertical-align: middle;
  text-align: left;
  width: 100%;

  animation: pulseIntroButtonAnimation ease-in-out 3.6s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.fixedDrawerHeader {
  z-index: 100;
  position: fixed;
  width: 100%;
  max-width: 540px;
  border-radius: 20px 20px 0px 0px;
}

.searchResultDetail {
  color: rgb(140, 140, 140);
  font-size: 13px;
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.preview {
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
}

.previewText {
  display: inline-block;
  padding: 12px;
  color: rgb(80, 80, 80);
  font-size: 14px;
}

.playDuration {
  vertical-align: middle;
  font-size: 13px;
  color: rgb(80, 80, 80);
  /* color: #6748FF; */
}

.haveAccount {
  padding: 16px 20px;
  font-size: 16px;
  color: rgb(120, 120, 120);
  background: rgb(251, 250, 252);
  width: 100%;
}

.loginLink {
  font-size: 16px;
  color: #6748FF;
  font-weight: 500;
}

.safeBottom {
  padding-bottom: env(safe-area-inset-bottom);
}

.soundPlayingContainer {
  pointer-events: none;
  width: 100%;
  padding: 0px 20px;
  max-width: 540px;
  position: fixed;
  bottom: calc(44px + env(safe-area-inset-bottom));
  text-align: center;
}

.trackTranscriptContainer {
  position: fixed;
  top: 0px;
  /* 124px is footer height. */
  bottom: calc(0px + env(safe-area-inset-bottom));
  right: 0px;
  left: 0px;
  overflow-y: scroll;
}

.trackAudioPlayerTopContainer {
  background: rgb(254, 254, 254);
  border-bottom: 8px solid rgb(242, 242, 242);
}

.clipUnsyncContainer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 540px;
  text-align: center;
  padding-top: 16px;
  padding-bottom: calc(54px + env(safe-area-inset-bottom));
}

.clipUnsyncContainerSmallWidth {
  padding-right: 16px;
  padding-left: 16px;
}

.settingsButton {
  margin: 0px 8px 0px 0px;
  height: 40px;
  width: 40px;
  background: rgb(242, 242, 242);
  display: inline-block;
  border-radius: 50%;
  vertical-align: top;

  /* font-size: 16px;
  padding: 8px 14px;
  margin: 0px 8px 0px 0px;
  border-radius: 8px;
  display: inline-table;

  color: rgb(251, 250, 252);
  background: rgb(30, 30, 30); */
}

.sectionTitleInTranscript {
  font-weight: 500;
  font-size: 16px;
  color: rgb(32, 33, 36);
  margin-bottom: 2px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackBottomControls {
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 538px;
  text-align: center;
  padding-top: 24px;
  padding-bottom: calc(12px + env(safe-area-inset-bottom));

  background: linear-gradient(rgba(255, 255, 255), rgba(242, 242, 247, .55));
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  /* border-top: 1px solid rgb(224, 224, 224); */
}

/* TODO move sync button to audio footer */
.clipSyncButtonContainer {
  position: sticky;
  bottom: 12px;
  width: 100%;
  max-width: 540px;
  text-align: center;
  pointer-events: none;
  padding: 0px 20px 0px 20px;
}

.syncButtonContainerBottom {
  position: sticky;
  width: 100%;
  text-align: center;
  pointer-events: none;
}

.blurBackground {
  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.bottomBar {
  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  border-radius: 8px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.14);
  text-align: center;
}

.sectionsControlContainer {
  position: fixed;
  bottom: calc(60px + env(safe-area-inset-bottom));
  width: 100%;
  max-width: 538px;
  padding: 0px 12px;
  z-index: 100;
}

.sectionsLabel {
  width: 200px;
  /* color: rgb(80, 80, 80); */
  font-size: 16px;
  display: inline-block;
  height: 44px;
  font-weight: 600;
  vertical-align: middle;
  /* border: 1px solid rgb(80, 80, 80); */

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sectionsLabelContainer {
  width: 224px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 4px;
}

.sectionsLabelText {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 1.2;
  font-weight: 500;
  font-size: 14px;
}

.syncButton {
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.14);
  width: 155px;
  border-radius: 20px;
  /* height: 36px; */
  pointer-events: visiblepainted;
}

.clipTwinklePoo {
  width: 70px;
  height: 70px;
  border-radius: 50%
}

.syncButtonDiv {
  background: rgb(30, 30, 30);
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
  padding: 10px 0px;
  color: rgb(251, 250, 252);
  border-radius: 20px;
  font-size: 15px;
  font-weight: 600;
}

.syncSectionContainer {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: calc(56px + env(safe-area-inset-bottom));
}

.red {
  color: red;
}

.firstTopicContainer {
  padding: 6px 20px; 
}

.firstTopicButton {
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 20px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
}

.settingForMeTopic {
  text-align: left;
  border-bottom: 1px solid rgb(224, 224, 224);
  padding: 12px 20px 12px 0px;
  margin-left: 20px;
}

.loadingSettingForMeTopic {
  margin: 8px 0px;
  border-radius: 16px;
  height: 56px;
  width: 100%;
  display: inline-block;
  background: rgb(242, 242, 242);
}

.nextPrevButtons {
  font-size: 16px;
  padding: 8px;
  width: 100%;
  border-radius: 16px;
  font-weight: 500;
}

.invisible {
  background: rgb(245, 245, 245);
}

.getStartedPromptMessage {
  padding: 14px 0px;
  margin: 8px 0px 8px 0px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 500;
}

.savePicksMessage {
  padding: 14px 0px;
  margin: 8px 0px 8px 0px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: 500;
}

.getStartedSelectTopic {
  padding: 14px 20px;
  margin: 8px 0px 8px 0px;
  background: rgb(242, 242, 242);
  border-radius: 16px;
}

#animate__delay-1-5s {
  --animate-delay: 1.5s;
  animation-name: fadeInUp;
}

.icon {
  width: 88px;
  border-radius: 20px;
}

.tagline {
  color: rgb(80, 80, 80);
  font-size: 14px;
  margin-top: 2px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.horizontalCenter {
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
}

.speakerInfo {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
}

.border {
  border: 1px solid rgb(224, 224, 224);
}

.shareOrCopyLink {
  margin: 16px 20px 0px;
}

.shareMessage {
  font-size: 14px;
  font-weight: 500;
  color: #5468ff;
  height: 36px;
  width: 100%;
  text-align: center !important;
  vertical-align: middle;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 20px;
}

.shareMessageIcon {
  vertical-align: text-top;
  margin-right: 5px;
  transform: rotate(-33deg);
  margin-top: -2px;
}

.headerButton {
  padding: 8px;
  color: rgb(112, 117, 122);
}

.footer {
  z-index: 100;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  background: rgb(242, 242, 247, .55);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding-bottom: env(safe-area-inset-bottom);
}

.playClipsFooter {
  z-index: 100;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  border-radius: 10px 10px 0px 0px;
  background: rgb(242, 242, 247, .55);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(13px);
  padding-bottom: env(safe-area-inset-bottom);
}

.footerNoBlur {
  z-index: 100;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
}

.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.subscribeFooter {
  position: fixed;
  bottom: 44px;
  width: 100%;
  max-width: 540px;

  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  padding-bottom: env(safe-area-inset-bottom);

  border-top: 1px solid rgb(224, 224, 224);
}

.messageContainer {}

.messageContainer button {
  text-align: left;
}

.searchTranscriptMessage {
  color: #202124;
  font-size: 16px;
  line-height: 1.4;
}

.message {
  color: #202124;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
}

.messageLarge {
  color: #202124;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.4;
}

.hideScrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.horizontalScroll {
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* transform: translateY(0);
  transition: transform 0.1s ease; */
  z-index: 1;
}

.pickFooterContainer {
  max-width: 540px;
  margin: 0px auto;
  border-top: 1px solid rgb(224, 224, 224);
  border-right: 1px solid rgb(224, 224, 224);
  border-left: 1px solid rgb(224, 224, 224);
}

.userTabContainer {
  overflow-y: scroll;
  /* for profile header buttons */
  padding-top: 50px;
}

.mainWithBottomMenu {
  padding-bottom: calc(55px + env(safe-area-inset-bottom));
}

.bottomPadding {
  padding-bottom: 20px;
}

.moreSuggestions {
  width: 100%;
  height: 44px;
  color: rgb(80, 80, 80);
  font-size: 15px;
  /* font-weight: 500; */
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
}

.hasCloseFooter {
  padding-bottom: calc(45px + env(safe-area-inset-bottom));
}

.closeFooterButton {
  width: 100%;
  font-size: 16px;
  height: 44px;
  text-align: center;
  color: rgb(120, 120, 120);
}

.hasShareClipFooterButton {
  padding-bottom: calc(69px + env(safe-area-inset-bottom));
}

.shareClipFooterButton {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  height: 68px;
  text-align: center;
  color: #6748FF;
}

.trackOverviewTimeRemaining {
  font-size: 50px;
  font-weight: 600;
  color: #202124;
  margin-bottom: 10px;
}

.trackOverviewType {
  font-size: 18px;
  font-weight: 500;
  color: #202124;
}

.trackOverviewTopButton {
  color: rgb(80, 80, 80);
  width: 100%;
  padding: 4px;
  vertical-align: middle;
}

.originalSpeedButton {
  font-size: 15px;
  color: rgb(80, 80, 80);
  padding: 16px 60px;

  border-radius: 16px;
  background: white;
  box-shadow: 0 0px 6px rgba(152, 147, 147, 0.14);
}

.originalSpeedButtonDisabled {
  font-size: 15px;
  color: rgb(80, 80, 80);
  padding: 16px;
}

.sourceImage {
  width: 42px;
  height: 42px;
  border-radius: 6px;
  vertical-align: middle;
}

.timeLabel {
  color: rgb(80, 80, 80);
  font-size: 13px;
  padding-left: 16px;
  vertical-align: middle;
  padding-top: 4px;
  display: inline-block;
}

.trackOverviewFullWidthButton {
  font-size: 16px;
  color: rgb(80, 80, 80);
  border: 1px solid rgb(210, 210, 210);
  padding: 8px 20px;
  width: 100%;
  border-radius: 20px;
  font-weight: 500;
}

.trackOverviewActionButton {
  font-size: 13px;
}

.trackOverviewActionIcon {
  height: 44px;
  width: 44px;
  /* border: 1px solid rgb(210, 210, 210); */
  border-radius: 50%;
  margin-bottom: -4px;
}

.sideDrawer {
  width: 85vw;
  max-width: 400px;
}

.entityInfoContent {
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
}

.entityInfoTopButton {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  height: 48px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
  margin: 0px 0px 16px;
  background: white;
}

.sync {
  color: white;
  font-weight: 600;
  font-size: 16px;
  height: 42px;
  margin: 18px 0px;
  border-radius: 20px;
  background: linear-gradient(4deg, rgba(215, 227, 252, 1) 0%, rgba(250, 221, 234, 1) 100%);
  width: 100%;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.14);
}

.speakerSuggestion {
  text-align: left;
  background: rgb(251, 250, 252);
}

.speakerSuggestion button {
  text-align: left;
}

.popupClose {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  color: rgb(120, 120, 120);
}

.emojiFeature {
  font-size: 40px;
  padding-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#searchTranscript {
  position: fixed;
  padding: 16px 20px;
  width: 100%;
  max-width: 538px;
  border-bottom: 1px solid rgb(224, 224, 224);
  border-radius: 20px 20px 0px 0px;

  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.searchTranscriptInput {}

.searchTranscriptRoot {
  border: 1px solid rgb(180, 180, 180);
  height: 40px;
  border-radius: 20px;
  background: white;
}

.searchPreview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
}

.algoliaCuston .aa-Autocomplete,
.algoliaCuston .aa-Autocomplete:focus,
.algoliaCustom .aa-DetachedSearchButton,
.algoliaCustom .aa-DetachedSearchButton:focus,
.aa-Autocomplete .aa-Form,
.aa-Autocomplete .aa-Form:focus,
.aa-DetachedOverlay .aa-Form,
.aa-DetachedOverlay .aa-Form:focus {
  border-radius: 8px !important;
  border: 0px !important;
  box-shadow: none !important;;
  height: 40px;
  font-size: 16px;
  background: rgb(245, 245, 245);
}

.aa-DetachedFormContainer .aa-DetachedCancelButton {
  padding-top: 4px;
}

.aa-SubmitIcon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ft2su1' focusable='false' aria-hidden='true' viewBox='3 2 19 19' data-testid='SearchIcon' width='44' height='44'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%2370757a'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
  color: rgba(0, 0, 0, 0) !important;
}


.aa-DetachedFormContainer .aa-SubmitButton {
  padding-left: 16px;
  padding-top: 2px;
}

.aa-Autocomplete .aa-SubmitButton {
  padding-left: 16px;
  padding-top: 4px;
}

.aa-DetachedFormContainer .aa-Form,
.aa-DetachedFormContainer .aa-Form:focus {
  border: 1px solid rgb(224, 224, 224) !important;
  box-shadow: none !important;
}

.aa-Input {
  padding-left: 20px;
}

.algoliaCustom {
  width: 100%;
  max-width: 538px;
}

.fixToTop {
  padding: 16px 20px;
  position: fixed;
  z-index: 100;
  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.algoliaCustom .ais-SearchBox-form {
  background: transparent;
}

.algoliaCustom .ais-SearchBox-input,
.algoliaCustom .ais-SearchBox-input:focus {
  border: 1px solid rgb(224, 224, 224);
  box-shadow: none;
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
}

.algoliaCustom .ais-SearchBox-input::placeholder {
  color: #999999;
}

.algoliaCustom .ais-SearchBox-input::-webkit-input-placeholder {
  color: #999999;
}

.algoliaCustom .ais-SearchBox-input:-ms-input-placeholder {
  color: #999999;
}

.algoliaCustom .ais-SearchBox-form::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ft2su1' focusable='false' aria-hidden='true' viewBox='3 2 19 19' data-testid='SearchIcon' width='44' height='44'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z' fill='%2370757a'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: cover;
}

.searchResults .ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type {
  border-radius: 0px;
}

.searchResults .ais-Hits-item {
  box-shadow: none;
  border-bottom: 0.5px solid rgb(224, 224, 224);
  margin-left: 20px;
  padding: 16px 20px 16px 0px;
}

.section {
  background: white;
  margin: 16px;
}

@keyframes shuffleAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.shuffleAnimation {
  animation: shuffleAnimation ease 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.zigzagBorderBottom {
  max-width: 540px;
  margin: 0px auto;
  position: relative;
  padding: 6px 12px;
  text-align: right;

  background: rgb(215, 227, 252);
  background: linear-gradient(4deg, rgba(215, 227, 252, 1) 0%, rgba(250, 221, 234, 1) 100%);
}

.zigzagBorderBottom:after {
  background: linear-gradient(-45deg, #ffffff 3px, transparent 0), linear-gradient(45deg, #ffffff 3px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 6px 6px;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 6px;
}

.thinDivider {
  height: 0.5px;
  background: rgb(224, 224, 224);
}

.thinRightDivider {
  margin-left: 20px;
  height: 0.5px;
  background: rgb(224, 224, 224);
}

.speakerName {
  font-size: 18px;
  font-weight: 600;
  color: #202124;
}

.speakerNameLarge {
  font-size: 22px;
  font-weight: 600;
  color: #202124;
}

.speakerNameSmall {
  font-size: 16px;
  color: #202124;
  font-weight: 500;
}

.speakerNameMiddle {
  font-size: 16px;
  font-weight: 400;
  color: #202124;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageTextPreview {
  color: rgb(80, 80, 80);
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speakerTrackTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speakerDescription {
  color: rgb(80, 80, 80);
  font-size: 14px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speakerDescriptionTwoLines {
  color: rgb(80, 80, 80);
  font-size: 14px;
  text-align: left;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.speakerDescriptionThreeLines {
  color: rgb(80, 80, 80);
  font-size: 14px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entityInfoSectionTitle {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px 0px;
}

.startSecondsButton {
  font-size: 18px;
  width: 100%;
  font-weight: 600;
  padding: 8px;
  border: 2px solid;
  border-radius: 20px;
}

.transcriptSmallHeading {
  font-size: 20px;
  color: #202124;
  font-weight: 500;
}

.transcriptTopSpeakerName {
  font-size: 14px;
  /* font-weight: 500; */
  color: #202124;
  max-width: 128px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.profilePictureDescription {
  font-size: 12px;
  color: rgb(80, 80, 80);
  max-width: 128px;

  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 100%;
  -webkit-box-orient: vertical; */

  overflow: hidden;
  text-overflow: ellipsis;
}

.profilePictureName {
  font-size: 14px;
  color: #202124;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 100%;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

.skipNextClip {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  margin: 0px auto;
  padding-top: 10px;
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  font-size: 14px;
  font-weight: 500;
}

.tipButton {
  width: 100%;
  padding: 12px 20px;
  background: #FFFFFF81;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-top: 1px solid rgb(224, 224, 224);
}

@keyframes pulseAnimation {
  0% {
    scale: 1.0;
  }

  50% {
    scale: 0.9;
  }

  100% {
    scale: 1.0;
  }
}

.pulse {
  animation: pulseAnimation ease 1.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

@keyframes glowAnimation {
  0% {
    opacity: 0.35
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.35
  }
}

.glow {
  animation: glowAnimation ease 1.7s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.timeRemaining {
  font-size: 13px;
}

.menuBackground {
  background: rgb(236, 236, 239);
}

.bottomHomeMenu {
  padding: 12px 16px;
  text-align: center;
}

.feedbackSubmit {
  font-size: 16px;
  font-family: 'Noto Sans', serif;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  margin: 13px 0px;
  /* border: 1px solid rgb(224, 224, 224); */
  background: black;
  color: white;
}

.feedbackTextArea {
  width: 100%;
  font-size: 16px;
  font-family: 'Noto Sans', serif;
  outline: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  padding: 13px;
  border: none;
  display: block;
  width: 100%;
  overflow: hidden;
  resize: none;
  min-height: 160px;
  /* line-height: 120px; */
}

.feedbackTextArea:focus {
  outline: 1px solid rgb(224, 224, 224);
}

.newSpeakerName {
  padding: 16px;
  width: 100%;
  border: none;
  font-size: 16px;
}

.newSpeakerName:focus {
  outline: none;
}

.editSpeakerTranscript {
  height: 44px;
  border: 1px solid rgb(224, 224, 224);
  text-align: center;
  font-weight: 500;
  font-size: 16px;

  border-radius: 8px;
  width: 100%;
}

.editMessageSectionTitle {
  font-size: 20px;
  font-weight: 600;
}

.getStartedSpeakersContainer {
  margin: 0px 20px;
}

.getStartedTopicsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin: 0px 20px;
}

.getStartedTopic {
  text-align: center;
  padding: 10px 0px;
  border-radius: 10px;
  border: 1px solid rgb(224, 224, 224);
  margin: 2px;
}

.getStartedTopicSelected {
  text-align: center;
  padding: 10px 0px;
  border-radius: 10px;
  border: 3px solid #5468ff;
}

.menuButton {
  vertical-align: middle;
}

.menuButtonTitle {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pickHeading {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 30px 20px 30px 0px;
  margin-left: 20px;
}

.infoSummary {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.podcastSummary {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.continueButton {
  border-radius: 8px;
  color: rgb(30, 30, 30);
  font-size: 16px;
  font-weight: 500;
  padding: 12px 0px;
  text-align: center;
  width: 100%;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  /* background: linear-gradient(224deg,rgb(255, 230, 255), rgb(230, 255, 255)); */
  background: linear-gradient(224deg, rgb(255, 224, 255), rgb(224, 255, 255));
  border: 2px solid white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.animate__animated.animate__zoomIn {
  --animate-duration: 0.2s;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 0.5s;
}

.animate__animated.animate__fadeOut {
  --animate-duration: 0.2s;
}

.animate__animated.animate__backInUp {
  --animate-duration: 0.4s;
}

.hslider-container {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.hslider-children {
  display: inline-block;
  /* max-width: 90%;
  min-width: 90%; */
  scroll-snap-align: start;
  position: relative;
  vertical-align: top;
  white-space: normal;
}

.clip-slider {
  margin: 8px 0px 8px 8px;
  border-radius: 8px;
  background: white;
  white-space: normal;
  overflow-y: scroll;
  overflow-x: auto;

  border: 1px solid rgb(224, 224, 224);
  position: relative;
}

.notInViewportOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.1); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure the overlay is on top */
}

.slider-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.slider-children {
  height: 100vh;
  scroll-snap-align: start;
  background: #000;
  position: relative;
  border: 1px solid transparent;
}

.clipSourceBottom {
  position: sticky;
  bottom: 0;
  padding: 10px 0px;
  z-index: 100;

  background: rgb(242, 242, 247, .55);
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(13px);
  /* border-top: 0.5px solid rgb(224, 224, 224); */
  border-radius: 0px 0px 8px 8px;
}

.clipFullEpisodeButton {
  position: absolute;
  bottom: -50px;
  z-index: 100;
}

.clipTopInfo {
  position: sticky;
  top: 0;
  padding: 16px 16px;
  /* border-bottom: 0.5px solid rgb(224, 224, 224); */

  /* background: rgb(242, 242, 247, .55); */
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(13px);
  border-radius: 8px 8px 0px 0px;
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  padding: 20px;
  position: relative;
}


.halfcircle-progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
}

.halfcircle-barOverflow {
  /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden;
  /* Comment this line to understand the trick */
  width: 90px;
  height: 45px;
  /* Half circle (overflow) */
  margin-bottom: -14px;
  /* bring the numbers up */
}

.halfcircle-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 5px solid #eee;
  /* half gray, */
  border-bottom-color: #0bf;
  /* half azure */
  border-right-color: #0bf;
}

.albumSliderContainer {
  overflow: hidden;
}

.slideLeft, .slideRight {
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.slideLeft img, .slideRight img {
  margin-left: 10px;
  border-radius: 0px;
  height: 80px;
  width: 80px;
}

.slideLeft {
  display: flex;
  justify-content: space-around;
  animation: scroll-horizontal-left 100s linear infinite;
}

@keyframes scroll-horizontal-left {
  0% {
    left: -100%;
  }
  100% {
    left: -1000%;
  }
}

.slideRight {
  display: flex;
  justify-content: space-around;
  animation: scroll-horizontal-right 100s linear infinite;
}

@keyframes scroll-horizontal-right {
  0% {
    left: -1000%;
  }
  100% {
    left: -100%;
  }
}

.play-pause-progress {
  width: 100%;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 36px;
  /* background-color: rgb(224, 224, 224, 0.5); */
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid rgb(210, 210, 210);
}

.progress-bar-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255, 0.3);
}

.progress-bar-fg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%; /* This will be updated dynamically */
  height: 100%;
  background-color: rgb(224, 224, 224);
  transition: width 0.1s ease;
}

.progress-bar-labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 5px 16px;
}

.loadingSpinner {
  border: 3px solid rgb(245, 245, 245);
  border-top: 3px solid rgb(120, 120, 120);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 10px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.profilePicContainer {
  width: 200px;
  height: 200px;
  position: relative;
}

.profilePicContainer img {
  width: 192px;
  height: 192px;
  margin: 4px;
  object-fit: cover;
  clip-path: url(#profilePicClipPath);
  -webkit-clip-path: url(#profilePicClipPath);
  vertical-align: middle;
  position: absolute;
  left: 0;
}

.profilePicContainer .profilePicBorder1 {
  width: 196px;
  height: 196px;
  margin: 2px;
  clip-path: url(#profilePicClipPath);
  -webkit-clip-path: url(#profilePicClipPath);
  background: white;
  position: absolute;
  left: 0;
  z-index: 1;
}

.profilePicContainer .profilePicBorder2 {
  width: 200px;
  height: 200px;
  clip-path: url(#profilePicClipPath);
  -webkit-clip-path: url(#profilePicClipPath);
  background: #5468ff;
  position: absolute;
  left: 0;
  z-index: 0;
}

.profilePicContainer .profilePicLoading {
  width: 200px;
  height: 200px;
  clip-path: url(#profilePicClipPath);
  -webkit-clip-path: url(#profilePicClipPath);
  background: linear-gradient(-45deg, rgb(234, 234, 238), rgb(228, 228, 232));
  position: absolute;
}

